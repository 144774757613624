
import { Component, Vue } from 'vue-property-decorator';

import { UserModule } from '@/store/modules';
import { IAuthUser } from '@/types/user.types';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component
export default class ReferralProgram extends Vue {
  referralCode = this.user?.referralCode ?? '';
  causeCode = this.user?.cause?.id ?? '';
  activeTenant = tenants[ActiveTenant];
  referralTextPrimary = this.activeTenant.referralProgram.referralTextPrimary;
  referralTextSecondary = this.activeTenant.referralProgram.referralTextSecondary ?? '';

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  copyReferralCode(): void {
    if (this.referralCode) {
      const element = document.createElement('textarea');
      element.value = `${window.location.origin}/register?referralCode=${this.referralCode}`;
      element.setAttribute('readonly', '');
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);
      this.$notifySuccess('Referral link has been copied!');
    }
  }

  copyCauseCode(): void {
    if (this.causeCode) {
      const element = document.createElement('textarea');
      element.value = `${window.location.origin}/register?causeId=${this.causeCode}`;
      element.setAttribute('readonly', '');
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);
      this.$notifySuccess('Cause link has been copied!');
    }
  }
}
